<template>
  <section class="top-filter">
    <div class="mb12">
      <FilterLayout
        @doReset="resetForm"
        @doSearch="doSearch"
        @doCollapseEffect="getChartContainerStyle"
        ref="filterLayoutRef"
        @showMoreForm="showMoreForm"
        :moreBtnVisible="moreBtnVisible"
        :isShowMoreFlag="isShowMoreList"
      >
        <template #customform>
          <a-form
              ref="formRef"
              class="form rms-form"
              layout="vertical"
              :model="formState"
          >
            <template v-for="(item,index) in queryList" :key="index">
              <template v-if="index<=3">
                <a-form-item :label="item.code" name="name" class="rms-form-item">
                  <template v-if="item.type==='2'">
                    <a-select
                        v-model:value="formState[item.inputDataType]"
                        :options="getOptions(item.inputDataType)"
                        placeholder="请选择"
                        allowClear
                        show-search
                    >
                    </a-select>
                  </template>
                  <template v-if="item.type==='3'">
                    <a-input
                        v-model:value="formState[item.inputDataType]"
                        :placeholder="getPlaceHoder(item.inputDataType)"
                        allowClear
                        autocomplete="off"
                    />
                  </template>
                  <template v-if="item.type==='5'">
                    <SelectWithAll
                        v-model:value="formState[item.inputDataType]"
                        placeholder="请选择"
                        mode="multiple"
                        showArrow
                        :maxTagCount="1"
                        allowClear
                        :options="getOptions(item.inputDataType)"
                        @change="getSelectChange(item.inputDataType)"
                    >
                    </SelectWithAll>
                  </template>
                  <template v-if="item.type==='6'">
                    <a-range-picker
                        v-model:value="formState[item.inputDataType]"
                        :placeholder="['Start date', 'End date']"
                        format="YYYY-MM"
                        valueFormat="YYYY-MM"
                        :mode="mode"
                        :open="item.inputDataType==='onProjectBeginDateList'?isOpenOnProject:isOpen"
                        @openChange="(status)=>getOpenChange(status,item.inputDataType)"
                        @panelChange="(value)=>getPanelChange(value,item.inputDataType)"
                    />
                  </template>
                </a-form-item>
              </template>
            </template>
            <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm" @moreFormSearch="moreSeachHanlder">
              <template v-for="(item,index) in queryList" :key="index">
                <template v-if="index>3">
                  <a-form-item :label="item.code" name="name" class="rms-form-item">
                    <template v-if="item.type==='2'">
                      <a-select
                          v-model:value="formState[item.inputDataType]"
                          :options="getOptions(item.inputDataType)"
                          placeholder="请选择"
                          allowClear
                          show-search
                      >
                      </a-select>
                    </template>
                    <template v-if="item.type==='3'">
                      <a-input
                          v-model:value="formState[item.inputDataType]"
                          :placeholder="getPlaceHoder(item.inputDataType)"
                          allowClear
                          autocomplete="off"
                      />
                    </template>
                    <template v-if="item.type==='5'">
                      <SelectWithAll
                          v-model:value="formState[item.inputDataType]"
                          placeholder="请选择"
                          mode="multiple"
                          showArrow
                          :maxTagCount="1"
                          allowClear
                          :options="getOptions(item.inputDataType)"
                          @change="getSelectChange(item.inputDataType)"
                      >
                      </SelectWithAll>
                    </template>
                    <template v-if="item.type==='6'">
                      <a-range-picker
                          v-model:value="formState[item.inputDataType]"
                          :placeholder="['Start date', 'End date']"
                          format="YYYY-MM"
                          valueFormat="YYYY-MM"
                          :mode="mode"
                          :open="item.inputDataType==='onProjectBeginDateList'?isOpenOnProject:isOpen"
                          @openChange="(status)=>getOpenChange(status,item.inputDataType)"
                          @panelChange="(value)=>getPanelChange(value,item.inputDataType)"
                      />
                    </template>
                  </a-form-item>
                </template>
              </template>
            </MoreFormList>
          </a-form>
        </template>
        <template #operationBtns>
          <div class="buttons-wrap right">
            <a-button
                class="left"
                type="primary"
                ghost
                @click="unfoldCollapseClick"
            >
              <span v-if="isExpansion">一键展开</span>
              <span v-else>一键收起</span>
            </a-button>
            <Tooltip title="导出">
              <a-button
                  class="left ml12"
                  type="primary"
                  ghost
                  @click="exportClick"
                  v-auth="['system:assignment:export']"
              >
                <ExportOutlined />
              </a-button>
            </Tooltip>
          </div>
        </template>
      </FilterLayout>
    </div>
    <FormModalBox v-model:visible="isShowFormSortBox" :sortFlag="false" :checkList="checkList" @closeModal="closeModal" @reset="queryFormList"></FormModalBox>
  </section>
</template>
<script>
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  onActivated,
  nextTick, watch,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import { getValueAndOps } from "@/utils/orgSelectData";
import {cloneDeep, debounce} from "lodash";
import { ExportOutlined} from "@ant-design/icons-vue";
import MoreFormList from '@/components/MoreFormList'
import FormModalBox from '@/components/FormModalBox'
import {dictionaryOpt} from '@/utils/common'
import Tooltip from '@/components/Tooltip'
export default defineComponent({
  name: "TopFilter",
  components: {Tooltip, ExportOutlined,MoreFormList,FormModalBox },
  emits: ["search", "export-click", "refresh-table", "getSize"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const formRef = ref();
    const mode = ref(["month", "month"]);
    const resetForm = () => {
      formRef.value.resetFields();
      Object.assign(formState,initData)
      updateParams();
      ctx.emit("search", currentState);
      getOrgList();
    };
    const orgFiveOneOps = ref([]);
    const orgThreeTwoOps = ref([]);
    const orgFourOneOneOps = ref([]);
    const assignmentTypeOps = ref([]);
    const assignmentStatusOps = ref([]);
    const accontOps = ref([]);
    let initData = {
      beginDate: undefined,
      endDate: undefined,
      onProjectBeginDate: undefined,
      onProjectEndDate: undefined,
      assignmentType: undefined,
      assignmentStatus: [],
      orgFiveOneList: [],
      orgThreeTwoList: [],
      orgFourOneOneList: [],
      accountList: [],
      engagementCondition: "",
      condition: "",
      beginDateList: [],
      onProjectBeginDateList: [],}
    const formState = reactive({...initData});
    const orgOpsObj = reactive({
      orgFiveOneOps: [],
      orgThreeTwoOps: [],
      orgFourOneOneOps: [],
    });

    const getOrgList = () => {
      $api.getOrgTwo_Three_Four_FiveList().then((res) => {
        const { orgFourOneOneList, orgFiveOneList, orgThreeTwoList } = res.data;
        orgOpsObj.orgFiveOneOps = dictionaryOpt(orgFiveOneList);
        orgOpsObj.orgThreeTwoOps = dictionaryOpt(orgThreeTwoList);
        orgOpsObj.orgFourOneOneOps = dictionaryOpt(orgFourOneOneList);
      });
    };
    const orgAllList = ref([]);
    const getOrgRelatedList = () => {
      $api.getAllOrgRelatedList().then((res) => {
        orgAllList.value = res.data;
      });
    };
    const orgThreeTwoChange = (type) => {
      const formOrgData = {
        orgFiveOneList: formState.orgFiveOneList,
        orgThreeTwoList: formState.orgThreeTwoList,
        orgFourOneOneList: formState.orgFourOneOneList,
      };
      const { orgsValue, orgOpsList } = getValueAndOps(
        orgAllList.value,
        formOrgData,
        orgOpsObj,
        type
      );
      Object.assign(formState, orgsValue);
      Object.assign(orgOpsObj, orgOpsList);
    };
    const getAccountItem = () => {
      $api.getAccountPlItemsApi().then((res) => {
        accontOps.value = dictionaryOpt(res.data.account);
      });
    };
    const handlePanelChange = (val) => {
      if (
        formState.beginDateList[1] &&
        formState.beginDateList[1]._d !== val[1]._d
      ) {
        isOpen.value = false;
      }
      formState.beginDate = val[0].format("YYYYMM");
      formState.endDate = val[1].format("YYYYMM");
      formState.beginDateList = [
        val[0].format("YYYY-MM"),
        val[1].format("YYYY-MM"),
      ];
    };
    const panelChangeOnProject = (val) => {
      if (
        formState.onProjectBeginDateList[1] &&
        formState.onProjectBeginDateList[1]._d !== val[1]._d
      ) {
        isOpenOnProject.value = false;
      }
      formState.onProjectBeginDate = val[0].format("YYYYMM");
      formState.onProjectEndDate = val[1].format("YYYYMM");
      formState.onProjectBeginDateList = [
        val[0].format("YYYY-MM"),
        val[1].format("YYYY-MM"),
      ];
    };
    const isOpenOnProject = ref(false);
    const isOpen = ref(false);
    const openChange = (panelStatus) => {
      if (panelStatus) {
        isOpen.value = true;
      } else {
        isOpen.value = false;
      }
    };
    const openOnProjectChange = (panelStatus) => {
      if (panelStatus) {
        isOpenOnProject.value = true;
      } else {
        isOpenOnProject.value = false;
      }
    };
    const disabledBeginDate = (current) => {
      return (
        current &&
        formState.endDate &&
        current >= formState.endDate.endOf("day")
      );
    };
    const disabledEndDate = (current) => {
      return (
        current &&
        formState.beginDate &&
        current <= formState.beginDate.startOf("day")
      );
    };
    let currentState = {};
    const updateParams = () => {
      currentState = cloneDeep(formState);
    };
    const doSearch = () => {
      updateParams();
      ctx.emit("search", currentState);
    };
    const exportClick = () => {
      ctx.emit("export-click", currentState);
    };
    const isExpansion = ref(true)
    const unfoldCollapseClick = () => {
      isExpansion.value = !isExpansion.value
      ctx.emit("unfoldCollapse-click", isExpansion.value);
    }
    const getChartContainerStyle = () => {
      nextTick(() => {
        ctx.emit("getSize");
      });
    };
    const getAssignmentTypes = () => {
      $api
        .getDictionaryList({
          params: {
            dictType: "assignmentType,assignmentStatus",
          },
        })
        .then((res) => {
          assignmentTypeOps.value = (res.data?.assignmentType || []).map(
            ({ dictCode, dictValue }) => ({
              label: dictValue,
              value: dictCode,
            })
          );
          assignmentStatusOps.value = (res.data?.assignmentStatus || []).map(
            ({ dictCode, dictValue }) => ({
              label: dictValue,
              value: dictCode,
            })
          );
        });
    };
    const show = ref(true);

    const queryList = ref([])
    const checkList = ref([])
    const moreBtnVisible = ref(false)
    const queryFormList = (data) => {
      let params = {
        firstMenu: 'basicDataManagement',
        secondMenu: 'assignmentInfo',
        isQueryDefault: data.isQueryDefault
      }
      $api.queryFilterSort(params).then((res) => {
        if(data.isQuery){
          queryList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
          queryList.value.length > 4?moreBtnVisible.value = true :moreBtnVisible.value = false
          checkList.value=[...queryList.value]
        }else{
          checkList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
        }
      });
    };

    
    const moreSeachHanlder = () => {
      doSearch()
      closeMoreForm()
    }

    const getOptions = (data)=> {
      switch (data) {
        case 'orgThreeTwoList':
          return orgOpsObj.orgThreeTwoOps
        case 'orgFourOneOneList':
          return orgOpsObj.orgFourOneOneOps
        case 'orgFiveOneList':
          return orgOpsObj.orgFiveOneOps
        case 'accountList':
          return accontOps.value
        case 'assignmentType':
          return assignmentTypeOps.value
        case 'assignmentStatus':
          return assignmentStatusOps.value
        default :
          ''  // 如果没
      }
    }
    const getPlaceHoder = (data)=> {
      switch (data) {
        case 'condition':
          return 'Name/GGID'
        case 'engagementCondition':
          return 'Engage.Desc/Code'
        default :
          return ''  // 如果没
      }
    }
    const getSelectChange = debounce((data)=> {
      switch (data) {
        case 'orgTwoList':
          return orgThreeTwoChange('orgTwo')
        case 'orgThreeTwoList':
          return orgThreeTwoChange('orgThreeTwo')
        case 'orgFourOneOneList':
          return orgThreeTwoChange('orgFourOneOne')
        case 'orgFiveOneList':
          return orgThreeTwoChange('orgFiveOne')
        default :
          ''  // 如果没
      }
    },500)
    const getOpenChange = (status,data)=> {
      data==='onProjectBeginDateList'?openOnProjectChange(status):openChange(status)
    }
    const getPanelChange = (value,data)=> {
      data==='onProjectBeginDateList'?panelChangeOnProject(value):handlePanelChange(value)
    }
    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const closeMoreForm = (data) => {
      if(data){
        console.log(123)
      }
      isShowMoreList.value = false
    }
    const closeModal = ()=> {
      isShowFormSortBox.value = false
    }
    watch(
        ()=>formState.beginDateList,
        (val)=>{
          if(val.length>0){
            formState.beginDate = val[0]
            formState.endDate = val[1]
          }else{
            formState.beginDate = ''
            formState.endDate = ''
          }
        }
    )
    watch(
        ()=>formState.onProjectBeginDateList,
        (val)=>{
          if(val.length>0){
            formState.onProjectBeginDate = val[0]
            formState.onProjectEndDate = val[1]
          }else{
            formState.onProjectBeginDate = ''
            formState.onProjectEndDate = ''
          }
        }
    )
    onMounted(() => {
      // nextTick(doSearch);
      getAssignmentTypes();
      getOrgList();
      getOrgRelatedList();
      getAccountItem();
      getChartContainerStyle();
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    });
    onActivated(() => {
      ctx.emit("refresh-table");
    });

    return {
      formState,
      formRef,
      mode,
      isOpenOnProject,
      isOpen,
      openChange,
      openOnProjectChange,
      handlePanelChange,
      panelChangeOnProject,
      disabledBeginDate,
      disabledEndDate,
      doSearch,
      resetForm,
      exportClick,
      orgOpsObj,
      orgFiveOneOps,
      orgThreeTwoOps,
      orgFourOneOneOps,
      assignmentTypeOps,
      assignmentStatusOps,
      accontOps,
      orgThreeTwoChange,
      show,
      updateParams,
      getOrgRelatedList,
      getAccountItem,
      getChartContainerStyle,
      queryFormList,
      queryList,
      checkList,
      getOptions,
      getPlaceHoder,
      getSelectChange,
      getOpenChange,
      getPanelChange,
      isShowMoreList,
      showMoreForm,
      moreBtnVisible,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      unfoldCollapseClick,
      isExpansion,
      moreSeachHanlder
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
}
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
}
</style>
