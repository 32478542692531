<template>
  <section class="org-list">
    <a-table
      ref="assignmentListRef"
      :columns="columns"
      :data-source="assignmentList"
      rowKey="id"
      :scroll="{
        y: tableHeight,
        x: 1200,
        scrollToFirstRowOnChange: true,
      }"
      :pagination="pagination"
      @change="handleTableChange"
      :expandIconColumnIndex="-1"
      :expandIconAsCell="false"
      :expandedRowKeys="curExpandedRowKeys"
    >
      <template #copyPersonEmail="{ text }">
        <a-popover placement="bottom">
          <template #content>
           {{text}}
          </template>
          <span>
            <span>{{text ? text.split(";")[0].split(" ")[0] + text.split(";")[0].split(" ")[1] : null}}</span>
            <br />
            <span>{{text ? text.split(";")[0].split(" ")[2] : null}}</span><span v-show="text? text.includes(';') : false">...</span>
          </span>
        </a-popover>
      </template>
      <template #type>
        <span>主</span>
      </template>
      <template #drop>
        <span style="color: #1890FF">●</span>
      </template>
      <template #RowRender="{ record }">
        <div v-if="record.staffAssignmentParts.length > 0">
          <span @click="segmentedEditingClick(record.id)" style="cursor: pointer;font-size: 0.7vw;">
            <span v-if="curExpandedRowKeys.includes(record.id)">  <DownOutlined />  </span>
            <span v-else> <RightOutlined /> </span>
          </span>
        </div>
      </template>
      <template #expandedRowRender="{ record }">
        <a-table
            :data-source="record.staffAssignmentParts"
            :columns="staffcolumns"
            rowKey="id"
            :pagination="false"
            :showHeader="false"
            class="staffClass"
        >
          <template #drop>
            <span style="color: #77CAED">●</span>
          </template>
          <template #type>
            <span>分</span>
          </template>
          <template #on_board_date="{ record }">
            {{record.startDate + '~' + record.endDate }}
          </template>
          <template #extensionAssumptionDate="{ record }">
            <span>{{ record.extensionAssumptionDate? record.extensionAssumptionDate : '-' }}</span>
          </template>
          <template #certainty="{ record }">
            <span>{{ record.certainty? record.certainty : '-' }}</span>
          </template>
        </a-table>
      </template>
      <template #extensionAssumptionDate="{ record }">
        <span>{{ record.extensionAssumptionDate? record.extensionAssumptionDate : '-' }}</span>
      </template>
      <template #certainty="{ record }">
        <span>{{ record.certainty? record.certainty : '-' }}</span>
      </template>
    </a-table>
  </section>
</template>
<script>
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { useTableHeight } from "@/utils/common";
import {
  assignmentStatus,
  releaseModeStatus,
  allDataSourceStatus,
} from "@/utils/formatStatus";
import { formatTableCol } from "@/utils/common";
import {DownOutlined, RightOutlined} from "@ant-design/icons-vue";

export default defineComponent({
  name: "CostCtrList",
  components: {RightOutlined, DownOutlined},
  setup() {
    const { $api } = useGlobalPropertyHook();

    const assignmentListRef = ref();
    const columns = [
      {
        title: " ",
        dataIndex: "",
        key: "",
        width: 30,
        slots: { customRender: "RowRender" },
      },
      {
        title: "",
        dataIndex: "",
        width: 30,
        slots: { customRender: "drop" },
      },
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Cost Ctr",
        dataIndex: "costCtr",
        key: "costCtr",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Cost Center",
        dataIndex: "costCenter",
        key: "costCenter",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Assignment Type",
        dataIndex: "assignmentType",
        key: "assignmentType",
        ellipsis: true,
        width: 180,
      },
      {
        title: "GGID",
        dataIndex: "userGgid",
        key: "userGgid",
        ellipsis: true,
        width: 180,
      },
      {
        title: "Candidate Name",
        dataIndex: "candidateName",
        key: "candidateName",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Candidate Grade",
        dataIndex: "candidateGrade",
        key: "candidateGrade",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Service Line",
        dataIndex: "serviceLine",
        key: "serviceLine",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Sub Service Line",
        dataIndex: "subServiceLine",
        key: "subServiceLine",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Candidate Contact",
        dataIndex: "candidateContact",
        key: "candidateContact",
        ellipsis: true,
        width: 200,
      },
      {
        title: "RM Name",
        dataIndex: "rmName",
        key: "rmName",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Org5.1",
        dataIndex: "orgFiveOne",
        key: "orgFiveOne",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Team",
        dataIndex: "team",
        key: "team",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Offering",
        dataIndex: "offering",
        key: "offering",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Industry Line",
        dataIndex: "industryLine",
        key: "industryLine",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Account",
        dataIndex: "account",
        key: "account",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Engagement Code",
        dataIndex: "engagementCode",
        key: "engagementCode",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Engagement Description",
        dataIndex: "engagementDescription",
        key: "engagementDescription",
        ellipsis: true,
        width: 240,
      },
      {
        title: "Work City",
        dataIndex: "workCity",
        key: "workCity",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Work Location",
        dataIndex: "workLocation",
        key: "workLocation",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        customRender: formatTableCol(assignmentStatus),
        ellipsis: true,
        width: 200,
      },
      {
        title: "PM GGID",
        dataIndex: "pmGgid",
        key: "pmGgid",
        ellipsis: true,
        width: 200,
      },
      {
        title: "PM Name",
        dataIndex: "pmName",
        key: "pmName",
        ellipsis: true,
        width: 280,
        customRender: ({ text }) => (
            <div>
              <div>{text ? text.split(" ")[0] + text.split(" ")[1] : null}</div>
              <div>{text ? text.split(" ")[2] : null}</div>
            </div>

        ),
        customCell:() => {
          return {
            style: {
              lineHeight: 1.2,
              padding:'6px 10px',
            }
          }
        }
      },
      {
        title: "Engagement Manager",
        dataIndex: "engagementManager",
        key: "engagementManager",
        ellipsis: true,
        width: 280,
        customRender: ({ text }) => (
            <div>
              <div>{text ? text.split(" ")[0] + text.split(" ")[1] : null}</div>
              <div>{text ? text.split(" ")[2] : null}</div>
            </div>

        ),
        customCell:() => {
          return {
            style: {
              lineHeight: 1.2,
              padding:'6px 10px',
            }
          }
        }
      },
      {
        title: "Leading CSM",
        dataIndex: "leadingCSM",
        key: "leadingCSM",
        ellipsis: true,
        width: 180,
        customRender: ({ text }) => (
            <div>
              <div>{text ? text.split(" ")[0] + text.split(" ")[1] : null}</div>
              <div>{text ? text.split(" ")[2] : null}</div>
            </div>

        ),
        customCell:() => {
          return {
            style: {
              lineHeight: 1.2,
              padding:'6px 10px',
            }
          }
        }
      },
      {
        title: "Copy",
        dataIndex: "copyPersonEmail",
        key: "copyPersonEmail",
        ellipsis: true,
        width: 300,
        // customRender: ({ text }) => (
        //   <a-tooltip placement="topLeft" title={text}>
        //     <span>
        //       <span>{text ? text.split(";")[0].split(" ")[0] + text.split(";")[0].split(" ")[1] : null}</span>
        //       <br />
        //       <span>{text ? text.split(";")[0].split(" ")[2] : null}</span>
        //     </span>
        //   </a-tooltip>
        // ),
        slots: { customRender: "copyPersonEmail" },
        customCell:() => {
          return {
            style: {
              lineHeight: 1.2,
              padding:'6px 10px',
            }
          }
        }
      },
      {
        title: "Assignment Segmentation Type",
        dataIndex: "assignmentSegmentationType",
        ellipsis: true,
        width: 280,
        slots: { customRender: "type" },
      },
      {
        title: "Project Onboard Date",
        dataIndex: "on_board_date",
        key: "onBoardDate",
        ellipsis: true,
        width: 250,
        sorter: true,
      },
      {
        title: "Segmentation End Date",
        dataIndex: "segmentationEndDate",
        ellipsis: true,
        width: 250,
        sorter: true,
      },
      {
        title: "Actual Release Date",
        dataIndex: "actual_release_date",
        key: "actualReleaseDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Estimate Release Date",
        dataIndex: "estimate_release_date",
        key: "estimateReleaseDate",
        ellipsis: true,
        width: 220,
        sorter: true,
      },
      {
        title: "Hours Per Day",
        dataIndex: "hoursPerDay",
        key: "hoursPerDay",
        ellipsis: true,
        width: 200,
      },
      {
        title: "FCST Extension Assumption Date",
        dataIndex: "extensionAssumptionDate",
        key: "extensionAssumptionDate",
        width: 200,
        slots: { customRender: "extensionAssumptionDate" }
      },
      {
        title: "确定性",
        dataIndex: "certainty",
        key: "certainty",
        width: 100,
        slots: { customRender: "certainty" }
      },
      {
        title: "Release Mode",
        dataIndex: "releaseMode",
        key: "releaseMode",
        customRender: formatTableCol(releaseModeStatus),
        ellipsis: true,
        width: 200,
      },
      {
        title: "Release Reason",
        dataIndex: "releaseReason",
        key: "releaseReason",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Source",
        dataIndex: "source",
        key: "source",
        customRender: formatTableCol(allDataSourceStatus),
        ellipsis: true,
        width: 200,
      },
      {
        title: "Create Time",
        dataIndex: "createTime",
        key: "createTime",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Create Operator",
        dataIndex: "createOperator",
        key: "createOperator",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Operation Time",
        dataIndex: "operationTime",
        key: "operationTime",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Operator",
        dataIndex: "operator",
        key: "operator",
        ellipsis: true,
        width: 200,
      },

      // 评价
      {
        title: "Attitude",
        dataIndex: "attitude",
        key: "attitude",
        ellipsis: true,
        width: 200,
      },
      {
        title: "English",
        dataIndex: "english",
        key: "english",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Synthetical Score",
        dataIndex: "syntheticalScore",
        key: "syntheticalScore",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Key Talent",
        dataIndex: "keyTalent",
        key: "keyTalent",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Retention Risk",
        dataIndex: "retentionRisk",
        key: "retentionRisk",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Skill",
        dataIndex: "skill",
        key: "skill",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Teamwork",
        dataIndex: "teamwork",
        key: "teamwork",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Review Date",
        dataIndex: "reviewDate",
        key: "reviewDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Summary",
        dataIndex: "summary",
        key: "summary",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Review Create Time",
        dataIndex: "reviewCreateTime",
        key: "reviewCreateTime",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Review Create Operator",
        dataIndex: "reviewCreateOperator",
        key: "reviewCreateOperator",
        ellipsis: true,
        width: 220,
      },
      {
        title: "Review Operation Time",
        dataIndex: "reviewOperationTime",
        key: "reviewOperationTime",
        ellipsis: true,
        width: 220,
      },
      {
        title: "Review Operator",
        dataIndex: "reviewOperator",
        key: "reviewOperator",
        ellipsis: true,
        width: 200,
      },
    ];
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch(searchCondition);
      },
    });
    const assignmentList = ref();
    let searchCondition = {};

    const doSearch = async (searchParams = searchCondition) => {
      const beginDate = searchCondition.beginDate
        ? searchCondition.beginDate
        : "";
      const endDate = searchCondition.endDate
        ? searchCondition.endDate
        : "";
      const onProjectBeginDate = searchCondition.onProjectBeginDate
        ? searchCondition.onProjectBeginDate
        : "";
      const onProjectEndDate = searchCondition.onProjectEndDate
        ? searchCondition.onProjectEndDate
        : "";
      const params = {
        ...searchParams,
        beginDate,
        endDate,
        onProjectBeginDate,
        onProjectEndDate,
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        sort,
      };
      await $api.assignmentExportList(params).then((res) => {
        pagination.total = res.data.total;
        assignmentList.value = res.data.resource;
        assignmentList.value = assignmentList.value.map(item => {
          return {
            ...item,
            staffAssignmentParts: item.staffAssignmentParts.map(i => {
              return {
                ...i,
                ...item,
                id: i.id,
                hoursPerDay: i.hoursPerDay
              }
            })
          }
        })
      });
      collapseClick(isExpansion.value)
    };

    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition = searchParams;
      doSearch(searchCondition);
    };

    const tableHeight = ref(0);

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    const sort = reactive({
      order: "",
      field: "",
    });
    const handleTableChange = (pag, filters, sorter) => {
      if (!sorter.order) {
        sorter.order = "";
        sorter.field = "";
      } else if(sorter.field === "segmentationEndDate") {
        sorter.columnKey = "actualReleaseDate";
        sorter.field = "actual_release_date";
        sorter.column = {
          dataIndex: "actual_release_date",
          ellipsis: true,
          key: "actualReleaseDate",
          sorter: true,
          title: "Actual Release Date",
          width: 200,
        }
      }
      Object.assign(sort, sorter);
      doSearch();
    };

    //分段
    const curExpandedRowKeys = ref([])
    const segmentedEditingClick = (rowkey) => {
      if (curExpandedRowKeys.value.length > 0) {
        let index = curExpandedRowKeys.value.indexOf(rowkey);
        if (index > -1) {
          curExpandedRowKeys.value.splice(index, 1);
        } else {
          curExpandedRowKeys.value.splice(0, curExpandedRowKeys.value.length);
          curExpandedRowKeys.value.push(rowkey);
        }
      } else {
        curExpandedRowKeys.value.push(rowkey);
      }
    }
    const staffcolumns = [
      {
        title: " ",
        dataIndex: "",
        key: "",
        width: 30,
      },
      {
        title: "",
        dataIndex: "",
        width: 30,
        slots: { customRender: "drop" },
      },
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Cost Ctr",
        dataIndex: "costCtr",
        key: "costCtr",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Cost Center",
        dataIndex: "costCenter",
        key: "costCenter",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Assignment Type",
        dataIndex: "assignmentType",
        key: "assignmentType",
        ellipsis: true,
        width: 180,
      },
      {
        title: "GGID",
        dataIndex: "userGgid",
        key: "userGgid",
        ellipsis: true,
        width: 180,
      },
      {
        title: "Candidate Name",
        dataIndex: "candidateName",
        key: "candidateName",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Candidate Grade",
        dataIndex: "candidateGrade",
        key: "candidateGrade",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Service Line",
        dataIndex: "serviceLine",
        key: "serviceLine",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Sub Service Line",
        dataIndex: "subServiceLine",
        key: "subServiceLine",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Candidate Contact",
        dataIndex: "candidateContact",
        key: "candidateContact",
        ellipsis: true,
        width: 200,
      },
      {
        title: "RM Name",
        dataIndex: "rmName",
        key: "rmName",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Org5.1",
        dataIndex: "orgFiveOne",
        key: "orgFiveOne",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Team",
        dataIndex: "team",
        key: "team",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Offering",
        dataIndex: "offering",
        key: "offering",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Industry Line",
        dataIndex: "industryLine",
        key: "industryLine",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Account",
        dataIndex: "account",
        key: "account",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Engagement Code",
        dataIndex: "engagementCode",
        key: "engagementCode",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Engagement Description",
        dataIndex: "engagementDescription",
        key: "engagementDescription",
        ellipsis: true,
        width: 240,
      },
      {
        title: "Work City",
        dataIndex: "workCity",
        key: "workCity",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Work Location",
        dataIndex: "workLocation",
        key: "workLocation",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        customRender: formatTableCol(assignmentStatus),
        ellipsis: true,
        width: 200,
      },
      {
        title: "PM GGID",
        dataIndex: "pmGgid",
        key: "pmGgid",
        ellipsis: true,
        width: 200,
      },
      {
        title: "PM Name",
        dataIndex: "pmName",
        key: "pmName",
        ellipsis: true,
        width: 280,
        customRender: ({ text }) => (
            <div>
              <div>{text ? text.split(" ")[0] + text.split(" ")[1] : null}</div>
              <div>{text ? text.split(" ")[2] : null}</div>
            </div>

        ),
        customCell:() => {
          return {
            style: {
              lineHeight: 1.2,
              padding:'6px 10px',
            }
          }
        }
      },
      {
        title: "Engagement Manager",
        dataIndex: "engagementManager",
        key: "engagementManager",
        ellipsis: true,
        width: 280,
        customRender: ({ text }) => (
            <div>
              <div>{text ? text.split(" ")[0] + text.split(" ")[1] : null}</div>
              <div>{text ? text.split(" ")[2] : null}</div>
            </div>

        ),
        customCell:() => {
          return {
            style: {
              lineHeight: 1.2,
              padding:'6px 10px',
            }
          }
        }
      },
      {
        title: "Leading CSM",
        dataIndex: "leadingCSM",
        key: "leadingCSM",
        ellipsis: true,
        width: 180,
        customRender: ({ text }) => (
            <div>
              <div>{text ? text.split(" ")[0] + text.split(" ")[1] : null}</div>
              <div>{text ? text.split(" ")[2] : null}</div>
            </div>

        ),
        customCell:() => {
          return {
            style: {
              lineHeight: 1.2,
              padding:'6px 10px',
            }
          }
        }
      },
      {
        title: "Copy",
        dataIndex: "copyPersonEmail",
        key: "copyPersonEmail",
        ellipsis: true,
        width: 300,
        slots: { customRender: "copyPersonEmail" },
        customCell:() => {
          return {
            style: {
              lineHeight: 1.2,
              padding:'6px 10px',
            }
          }
        }
      },
      {
        title: "Assignment Segmentation Type",
        dataIndex: "assignmentSegmentationType",
        ellipsis: true,
        width: 280,
        slots: { customRender: "type" },
      },
      {
        title: "Project Onboard Date",
        dataIndex: "startDate",
        key: "startDate",
        ellipsis: true,
        width: 250,
        //slots: { customRender: "on_board_date" },
      },
      {
        title: "Segmentation End Date",
        dataIndex: "endDate",
        key: "endDate",
        ellipsis: true,
        width: 250,
      },
      {
        title: "Actual Release Date",
        dataIndex: "actual_release_date",
        key: "actualReleaseDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Estimate Release Date",
        dataIndex: "estimate_release_date",
        key: "estimateReleaseDate",
        ellipsis: true,
        width: 220,
      },
      {
        title: "Hours Per Day",
        dataIndex: "hoursPerDay",
        key: "hoursPerDay",
        ellipsis: true,
        width: 200,
      },
      {
        title: "FCST Extension Assumption Date",
        dataIndex: "extensionAssumptionDate",
        key: "extensionAssumptionDate",
        width: 170,
        slots: { customRender: "extensionAssumptionDate" }
      },
      {
        title: "确定性",
        dataIndex: "certainty",
        key: "certainty",
        width: 100,
        slots: { customRender: "certainty" }
      },
      {
        title: "Release Mode",
        dataIndex: "releaseMode",
        key: "releaseMode",
        customRender: formatTableCol(releaseModeStatus),
        ellipsis: true,
        width: 200,
      },
      {
        title: "Release Reason",
        dataIndex: "releaseReason",
        key: "releaseReason",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Source",
        dataIndex: "source",
        key: "source",
        customRender: formatTableCol(allDataSourceStatus),
        ellipsis: true,
        width: 200,
      },
      {
        title: "Create Time",
        dataIndex: "createTime",
        key: "createTime",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Create Operator",
        dataIndex: "createOperator",
        key: "createOperator",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Operation Time",
        dataIndex: "operationTime",
        key: "operationTime",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Operator",
        dataIndex: "operator",
        key: "operator",
        ellipsis: true,
        width: 200,
      },

      // 评价
      {
        title: "Attitude",
        dataIndex: "attitude",
        key: "attitude",
        ellipsis: true,
        width: 200,
      },
      {
        title: "English",
        dataIndex: "english",
        key: "english",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Synthetical Score",
        dataIndex: "syntheticalScore",
        key: "syntheticalScore",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Key Talent",
        dataIndex: "keyTalent",
        key: "keyTalent",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Retention Risk",
        dataIndex: "retentionRisk",
        key: "retentionRisk",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Skill",
        dataIndex: "skill",
        key: "skill",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Teamwork",
        dataIndex: "teamwork",
        key: "teamwork",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Review Date",
        dataIndex: "reviewDate",
        key: "reviewDate",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Summary",
        dataIndex: "summary",
        key: "summary",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Review Create Time",
        dataIndex: "reviewCreateTime",
        key: "reviewCreateTime",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Review Create Operator",
        dataIndex: "reviewCreateOperator",
        key: "reviewCreateOperator",
        ellipsis: true,
        width: 220,
      },
      {
        title: "Review Operation Time",
        dataIndex: "reviewOperationTime",
        key: "reviewOperationTime",
        ellipsis: true,
        width: 220,
      },
      {
        title: "Review Operator",
        dataIndex: "reviewOperator",
        key: "reviewOperator",
        ellipsis: true,
        width: 200,
      },
    ]

    const isExpansion = ref(true)
    const collapseClick = (data) => {
      isExpansion.value = data
      if(data){
        curExpandedRowKeys.value = []
      } else {
        assignmentList.value.forEach(item => {
          if(item.staffAssignmentParts.length > 0) {
            curExpandedRowKeys.value.push(item.id)
          }
        })
      }
    }

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return {
      assignmentListRef,
      columns,
      pagination,
      assignmentList,
      searchClick,
      tableHeight,
      doSearch,
      getSize,
      sort,
      handleTableChange,
      curExpandedRowKeys,
      segmentedEditingClick,
      staffcolumns,
      collapseClick,
      isExpansion,
    };
  },
});
</script>
<style scoped lang="less">
// 覆盖ant-design样式
//::v-deep .ant-table-body {
//  overflow: auto;
//}
//
//:deep(.ant-table-thead) {
//  >tr>th {
//    white-space: nowrap;
//  }
//}
//
//:deep(.ant-table-tbody) {
//  >tr>td {
//    white-space: nowrap;
//  }
//}

.org-list {
  display: flex;
  width: 100%;
  max-height: calc(100% - 88px);

  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  .org-info {
    width: 300px;
    //margin-left: 20px;
    background: #f4f4f4;
  }
}
.staffClass {
  :deep(.ant-table .ant-table-tbody > tr > td){
    border-bottom: 0;
    padding: 0.2vw 0.83333vw !important;
  }
  :deep(.ant-table .ant-table-tbody){
    background: #FCFCFC;
  }
  :deep(.ant-table-layout-fixed table) {
    border-bottom: 1px solid #f0f0f0;
    border-top: 1px solid #f0f0f0;
  }
  //border-bottom: 1px solid #f0f0f0;
}
</style>
