<template>
  <section class="app-container" ref="assignmentRef">
    <Filter
      @search="handleSearch"
      @export-click="exportClick"
      @refresh-table="handleResetTable"
      @getSize="getSize"
      @unfoldCollapse-click="unfoldCollapseClick"
    />
    <AssignmentList ref="assignmentListRef"></AssignmentList>
    <a-modal
        :getContainer="()=>$refs.assignmentRef"
        v-model:visible="modalVisible"
        width="20.8vw"
        title="导出"
        ok-text="确定"
        cancel-text="取消"
        @ok="handleOk"
        @cancel="handleCancel"
        centered
    >
      <div class="mb12">
        <a-form layout="vertical" :model="formState" ref="formRef">
          <a-form-item label="导出数据类型" name="exportDataType" :rules="[{
            required: true,
            message: '请选择导出数据类型',
            trigger: 'blur' }]"
          >
            <a-select style="width: 100%" v-model:value="formState.exportDataType" :options="exportOps" placeholder="请选择"/>
          </a-form-item>
        </a-form>
      </div>
    </a-modal>
  </section>
</template>
<script>
import { defineComponent, ref, reactive } from "vue";
import Filter from "./components/Filter";
import AssignmentList from "./components/AssignmentList";
// import { downloadFile } from "@/utils/common";
import { useGlobalPropertyHook } from "@/hooks/common";
import { message } from "ant-design-vue";

export default defineComponent({
  name: "exportAssignment",
  components: {
    AssignmentList,
    Filter,
  },
  setup() {
    const { $api } = useGlobalPropertyHook();

    const assignmentListRef = ref();
    const handleSearch = (searchParams) => {
      assignmentListRef.value.searchClick(searchParams);
    };
    const handleResetTable = () => {
      assignmentListRef.value.doSearch();
    };

    const sort = reactive({
      order: "",
      field: "",
    });
    const exportClick = (formState) => {
      const beginDate = formState.beginDate
        ? formState.beginDate
        : "";
      const endDate = formState.endDate
        ? formState.endDate
        : "";
      const params = {
        ...formState,
        beginDate,
        endDate,
        sort,
      };
      // $api.assignmentExport(params).then((res) => {
      //   downloadFile(res.file, res.filename);
      // });
      exportDataParams.value = params
      modalVisible.value = true
    };

    const modalVisible = ref(false);
    const formState = ref({
      exportDataType: "",
    });
    const exportOps = ref([
      {
        value: '1',
        label: "主调令及分段调令",
      },
      {
        value: '0',
        label: "仅主调令",
      },
      {
        value: '2',
        label: "仅分段调令",
      },
    ]);
    const exportDataParams = ref()
    const formRef = ref()
    const handleOk = () => {
      formRef.value.validate().then(() => {
        console.log(exportDataParams.value)
        exportDataParams.value.partType = formState.value.exportDataType
        $api.assignmentExport(exportDataParams.value).then(() => {
          // downloadFile(res.file, res.filename);
          message.success("文件正在处理中，请点击”文件中转站“按钮下载打包文件")
        });
      });
    };
    const handleCancel = () => {
      formRef.value.resetFields();
      modalVisible.value = false
    }
    const isExpansion = ref(true)
    const unfoldCollapseClick = (data) => {
      isExpansion.value = data
      assignmentListRef.value.collapseClick(data);
    }

    const getSize = () => {
      assignmentListRef.value.getSize();
    };
    return {
      assignmentListRef,
      handleSearch,
      handleResetTable,
      exportClick,
      getSize,
      modalVisible,
      formState,
      exportOps,
      handleOk,
      exportDataParams,
      formRef,
      handleCancel,
      isExpansion,
      unfoldCollapseClick,
    };
  },
});
</script>
